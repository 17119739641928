<template>
	<div class="manual">
		<Nav></Nav>
		<div class="manual-desc">
			<h1>群使用手册</h1>
			<div><img v-lazy="'https://i.loli.net/2021/01/30/ITEeli4Du2zwcXf.png'" v-popover:popover>
			<el-popover
			    ref="popover"
			    placement="top-start"
			    width="250"
			    trigger="manual"
				popper-class='miao-popper'
				v-model="show"
			    :content="content">
			</el-popover></div>
			<el-tabs type="border-card" v-model="activeName" class="manual-card" @tab-click="handleClick">
			  <el-tab-pane name="desc">
			    <span slot="label"><i class="el-icon-c-scale-to-original"></i> 群简介</span>
				<p>- 群号:<el-link type="primary" href="https://jq.qq.com/?_wv=1027&k=VdvS5t9T">877164600</el-link>，于2019年10月07开始，搬运pixiv 每日top50，搬运了接近1年吧，在2020年08月22日开始由于各种原因鸽了。</p>
				<p>- 至于现在，由于电脑硬盘坏了，只有C盘了，也没有资金买，又加上快毕业了也不像以前有那么多时间，就暂时不打算再搬运了。</p>
				<p>- 本群虽说叫pivix每日搬运，但基本啥都聊，非常欢迎大家把群分享给有相同爱好的人。</p>
				<p>- 想了解P站，这一篇<el-link type="primary" href="/article/5">Pixiv使用指南</el-link>或许能帮助你。</p>
				<p>- 有什么问题请在群里问哦。</p>
				<p class="payimg">- <span>由于服务器的费用比较高，咱也没有钱钱，所以来要饭饭，(★ ω ★)</span><img v-lazy="'/img/pay/weixin.jpg'" preview='0' preview-text="微信" alt="weixin" ><img v-lazy="'/img/pay/alipay.jpg'" preview='0' preview-text="支付宝" alt="alipay"><img v-lazy="'/img/pay/qqpay.png'" preview='0' preview-text="QQ" alt="qq"></p>
			  </el-tab-pane>
			  <el-tab-pane name="about">
				<span slot="label"><i class="el-icon-bicycle"></i> 群相关</span>
				<p><span>QQ分群：</span><el-link type="primary" href="https://jq.qq.com/?_wv=1027&k=6XOFGuSE">Pixiv 每日精选(793752494)</el-link></p>
				<p><span>QQ里群：</span><el-link type="info">未公开(申请条件见群规则)</el-link></p>
				<p><span>TG群聊：</span><el-link type="primary" href="https://t.me/joinchat/Kr4unBVfhYksgjvriKgDzg">PixivLips</el-link></p>
				<p><span>TG频道：</span><el-link type="primary" href="https://t.me/PixivLips">PixivLips</el-link></p>
				<p><span>图片墙：</span><el-link type="primary" href="https://kasuie.top/image">图片墙</el-link></p>
				<p><span>资源导航：</span><el-link type="primary" href="https://kasuie.top/nav">资源导航(国内访问比较快)</el-link><el-link type="primary" href="https://pixlips.ga">资源导航(Github page)</el-link></p>
				<p><span>图包打包：</span><el-link type="primary" href="https://kasuie.top/package">图包打包</el-link></p>
				<p><span>搜图bot：</span>
					<el-link type="primary" href="tencent://Message/?Uin=3034538552&amp;websiteName=q-zone.qq.com&amp;Menu=yes">C.C.</el-link>
					<el-link type="info">(qq:3034538552,推荐私聊，直接发图即可搜图)</el-link>
				</p>
			  </el-tab-pane>
			  <el-tab-pane name="rules">
				  <span slot="label"><i class="el-icon-s-data"></i> 群规则</span>
				  <ul>
				  	<li><span>01.</span> No R18，别发露点图，同时尺度大的也请不要发</li>
					<li><span>02.</span> 禁止讨论政治，禁止讨论敏感话题</li>
					<li><span>03.</span> 新人进群请在24小时内发言一次哦~</li>
					<li><span>04.</span> 请勿以任何形式打广告，包括但不限于空间点赞，扩列等，但如果是P站自己的画作，B站自己制作的视频等原创类，最多一个稿件一次推广(该推广权利仅群活跃等级为二乃及以上享有</li>
					<li><span>05.</span> 请勿撕逼(没必要要让别人认同你的观点)，本人应付不来脾气暴躁的，所以希望大家都和谐一点，有话好好说，感谢~</li>
					<li><span>06.</span> 请勿恶意玩梗，刷梗，不要盲目跟风，网上的东西建议要自己去了解一下再做评价</li>
					<li><span>07.</span> 请勿传播无证据信息,谣言,恶意剧透等</li>
					<li><span>08.</span> 群相册相关
						<ul>
							<li><span>08-1.</span> 群相册上传图片必须上传原图，也不要上传在QQ，微博等别人转载的地方保存的图片</li>
							<li><span>08-2.</span> 按照分类选择合适的相册进行上传，如无相应的相册可以自行创建</li>
							<li><span>08-3.</span> 请避免创建相同类别的相册，个人相册，以及违反群规的图片</li>
						</ul>
					</li>
					<li><span>09.</span> 会不定期清理潜水的，大概两个月一次，如果有什么事是要长期潜水的，请在群名片括号备注一下吖</li>
					<li><span>10.</span> 禁止恶意刷屏，管理员也请勿滥权，请勿刷屏</li>
					<li><span>11.</span> 如果怂恿群友违反群规成功，怂恿的人和违反的人一起处理</li>
					<li><span>12.</span> 如违反规定，视情况惩罚</li>
					<li><span>13.</span> 里群要求主群活跃头衔达到三玖（lv4）+得到管理员的主观认同，达到要求找管理要群号即可</li>
					<li><span>14.</span> 想当管理员的请看2020年3月28日的公告，有具体说，现在也适用</li>
					<li><span>15.</span> ......</li>
				  </ul>
			  </el-tab-pane>
			  <el-tab-pane name="tools" :class="tools?'':'tools-hidden'">
				  <span slot="label"><i class="el-icon-s-promotion"></i> 群工具</span>
				  <MTool v-if="tools"></MTool>
				  <el-button v-show="!tools" type="text" @click="open">点我进行密码验证即可查看该部分内容</el-button>
			  </el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>

<script>
import Nav from '@/components/Nav.vue'
import MTool from '@/components/MTool.vue'

export default {
	metaInfo: {
	    title: '群使用手册 - KASUIEの次元',
	    meta: [
	  	  {
	  		  name: 'description',
	          content: 'pixiv每日top50搬运群使用手册',
	  	  },
	    ]
	},
	inject:['reload'],
	data() {
		return {
			activeName: 'rules',
			show: false,
			tools: false,
			content: '',
			contents: ['你好呀，相遇即是缘分 (●ˇ∀ˇ●)','是一些网站哒 ヾ(≧▽≦*)o','要仔细阅读哦 ( •̀ ω •́ )✧','希望有帮到你 ♪(^∇^*)','这一部分被我隐藏了啦(っ´Ι`)っ'],
		}
	},
	components: {
		Nav,
		MTool
	},
	methods: {
		handleClick(tab) {
			this.show = true
			if(!this.tools&&tab.index=='3'){
				this.content = this.contents[4]
			}else{
				this.content = this.contents[tab.index]
			}
			setTimeout(() => {
				this.show = false
			},5000)
		},
		open() {
			this.$prompt('请输入密码', '提示', {
			  confirmButtonText: '确定',
			  cancelButtonText: '取消',
			}).then(({ value }) => {
				if(value=='pixiv'){
				  this.$cookies.set("tools", 'pixlips' , "1m")
				  this.$notify({
				  	title: '成功',
				  	message: '密码正确，页面刷新后点击可查看',
				  	type: 'success'
				  });
				  setTimeout(() => {
					  this.reload()
				  },2000)
				}else{
					this.$notify.error({
						title: '警告',
						message: '密码错误'
					});
				}
			}).catch(() => {
			  this.$message({
				type: 'info',
				message: '取消输入'
			  });       
			});
		},
	},
	watch: {
	},
	created() {
		if(this.$cookies.isKey('tools')){
			if(this.$cookies.get("tools")=='pixlips'){
				this.tools = true
			}
		}
	},
	mounted() {
		document.body.style.overflow='hidden'
		setTimeout(() => {
			this.$store.commit('showLoading')
			document.body.style.overflow=''
		},2000)
	},
	destroyed() {
		this.$store.commit('loading')
	}
}
</script>

<style>
.manual{
	min-height: 100%;
	color: var(--theme-color);
}
.manual-desc{
	width: 70%;
	margin: 0 auto;
	margin-top: 5em;
}
.manual-desc h1{
	color: white;
}
.manual-desc h1 img{
	width: 2em;
	vertical-align: middle;
	border-radius: 50%;
	margin-right: .2em;
}
.manual-card{
	margin-top: 4em;
	padding: 0 0 2em;
	background-color: var(--theme-background-color);
	border-radius: 2em;
}
.manual-card .el-tabs__content{
	position: relative;
	text-align: start;
}
.manual-desc>div>img{
	position: relative;
	width: 10em;
	top: 5.29em;
	z-index: 3;
}
.manual-card .el-tabs__content .el-tab-pane ul{
	list-style: none;
	margin-left: .5em;
}
.manual-card .el-tabs__content .el-tab-pane ul li{
	margin: .5em;
}
.manual-card .el-tabs__content .el-tab-pane ul li span{
	line-height: 2em;
	font-weight: 600;
}
#pane-about>p>span{
	color: var(--theme-color);
}
#pane-rules ul li span{
	color: var(--theme-color);
}
.tools-hidden{
	text-align: center;
}
.manual-card .el-tabs__content .el-tab-pane>ul>li{
	margin: .7em 0;
	font-size: 1.1em;
}
.manual-card .el-tabs__content .el-tab-pane>ul>li>ul>li{
	font-size: .9em;
}
.manual-card .el-tabs__content .el-tab-pane .el-link{
	margin: 0 .5em;
}
.manual-card .el-tabs__content .el-tab-pane .el-link.el-link--primary{
	font-weight: 600;
	color: #238efd;
}
.manual-card .el-tabs__content .el-tab-pane .el-link.el-link--info{
	color: gray;
}
.payimg img{
	width: 33%;
}
.payimg span{
	display: block;
}
.manual-card .el-tabs__content .el-tab-pane p{
	font-size: 1.1em;
	margin: .5em 0;
}
.miao-popper{
	background: padding-box;
	border: 1px solid #d0e4ec;
	color: #ffffff;
	border-radius: .5em;
	z-index: 10 !important;
}
.el-popper[x-placement^=top] .popper__arrow{
	border-top-color: #d0e4ec;
}
.darkApp .manual-card .el-tabs__content .el-tab-pane .el-link.el-link--primary{
	color: #8dc5ff;
}
@media screen and (max-width: 770px) {
	.manual-desc{
		width: 97%;
	}
	.manual-card .el-tabs__content .el-tab-pane ul li{
		margin: .5em 0;
	}
	.manual-desc .el-tabs__item{
		padding: 0 10px;
	}
	#tab-about{
		padding-left: 10px;
	}
}
</style>
